.video-field {
  background: #f0f0f0;
  height: 180px;
  width: auto;

  &__video {
    height: 100%;
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100%;
  }
}

.intro-video-edit-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;

  &__title {
    margin: 0 0 10px;
  }

  &__video-container {
    margin-bottom: 10px;
  }

  &__upload-button {
    margin-bottom: 10px;
  }

  &__horizontal-line {
    width: 100%;
    margin-bottom: 25px;
    border-bottom: 1px solid #888888;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
  }

  &__submit-button {
    text-transform: uppercase;
    margin-right: 10px;
  }

  &__loading-layer {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;

    &.-active {
      display: block;
      cursor: wait;
    }
  }
}

.tracking-list-form {
  &__date-form {
    .MuiToolbar-root {
      display: none;
    }
  }

  &__date-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }

  &__date-text {
    display: inline;
    margin-right: 8px;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  &__date-error {
    color: #ff0000;
    height: 18px;
    margin-bottom: 20px;
  }

  &__horizontal-line {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #888888;
  }

  &__user-list {
    padding: 16px;
  }

  &__title {
    margin: 0;
  }

  &__global-list {
    padding: 16px;
  }
}

/* .tracking-download-form {
  &__input-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiAutocomplete-root {
      min-width: 350px;
    }
  }

  &__button {
    margin-bottom: 16px;
    
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  &__horizontal-line {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #888888;
  }

  .MuiToolbar-root {
    display: none;
  }
} */

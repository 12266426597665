.tracking-users-edit {
  &__title {
    margin: 0 0 10px;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    background: #DEDEDE;
    width: calc(100% - 30px);
    margin-bottom: 20px;
    padding: 0 15px;
  }

  &__data {
    font-weight: 400;
    margin-left: 15px;
  }

  &__array-field-container {
    width: 100%;

    & > div:first-child {
      width: 100%;
    }

    & th:first-child,
    & td:first-child {
      display: none;
    }

    & th:last-child,
    & td:last-child {
      width: 30%;
    }

    &.-has-search-type {
      & th:nth-child(3),
      & th:nth-child(4),
      & td:nth-child(3),
      & td:nth-child(4) {
        width: 15%;
        text-align: center;
      }
    }
  }

  &__message {
    display: block;
    background: #DEDEDE;
    width: 100%;
    font-size: 18px;
    padding: 10px 0;
    text-align: center;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  &__button-download {
    margin: 10px 0 20px;
  }
}

.custom-images-display-field {
  margin-bottom: 20px;

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__label {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.75em;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.5;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.6);
  }

  &__image-container {
    height: 180px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 5px;
      margin-right: 5px;
    }

    &:hover {
      .custom-images-display-field__button-delete {
        display: flex;
      }
    }
  }

  &__image {
    height: 100%;
  }

  &__button-delete {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &__icon {
    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #D32F2F;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      position: relative;
    }

    &-dash {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #FFFFFF;
      width: 10px;
      height: 2px;
      transform: translate(-50%, -50%);
    }
  }
}

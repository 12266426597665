.original-image {
  &__label {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.75em;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.5;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.6);
  }

  &__container {
    height: 180px;
  }

  &__image {
    height: 100%;
  }
}

.button {
  display: flex;
  justify-content: center;
  color: #1976d2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 4px 10px;
  border: 1px solid #1976d2;
  border-radius: 4px;
  transition-duration: 0.3s;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.04);
    transition-duration: 0.3s;
  }

  &.-disabled {
    pointer-events: none;
    color: #888888;
    border-color: #eeeeee;
    background-color: #eeeeee;
    transition-duration: 0.3s;
  }

  &.-full-blue {
    color: #ffffff;
    background: #1976d2;
    transition-duration: 0.3s;

    &:hover {
      background: #1565c0;
      transition-duration: 0.3s;
    }

    &.-disabled {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.26);
      background: rgba(0, 0, 0, 0.12);
      transition-duration: 0.3s;
    }
  }
}
